#erp-v2 {
	.trigger {
		padding: 0 24px;
		font-size: 18px;
		line-height: 64px;
		cursor: pointer;
		transition: color 0.3s;
		&:hover {
			color: #1890ff;
		}
	}

	.sider-layout-sidebar {
		overflow: auto;
		max-width: max-content !important;
		flex: auto !important;
	}

	.logo {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 4px 15px;
		img {
			height: 48px;
		}
	}

	.site-layout .site-layout-background {
		&.content {
			min-height: calc(100vh - 112px);
			overflow: initial;
			max-width: 1200px;
			width: 100%;
			margin: 16px auto;
		}
	}

	.site-layout-true .site-layout-background {
		// background: #fff;
		&.content {
			min-height: calc(100vh - 112px);
			overflow: initial;
			max-width: 1200px;
			width: 100%;
			margin: 16px auto;
		}
	}
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.account-dropdown {
		img {
			width: 30px;
			height: 30px;
		}
	}

	.divider {
		width: 2px;
		height: 30px;
		background: #bababa;
		margin-left: 70px;
	}

	.account-button {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;

		background-color: #22252b;
		border-color: #22252b;
		padding: 20px 15px;
		border-radius: 30px;

		img {
			width: 25px;
			height: 25px;
			margin-right: 10px;
		}
		strong {
			color: white;
			margin-right: 10px;
		}
		.anticon {
			color: white;
		}
	}
}
